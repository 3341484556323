// src/api/axiosInstance.ts

import axios from "axios";
import { store } from "../store/store.ts"; // Import your Redux store
import { getAPIURL } from "./index.ts";
import { setAuthFailed } from "../store/slices/authenticationSlice.ts";

const axiosInstance = axios.create({
  baseURL: getAPIURL(""),
  withCredentials: true, // Include cookies in requests
});

// Create a separate instance for authentication requests
export const authAxiosInstance = axios.create({
  baseURL: getAPIURL(""),
  withCredentials: true,
});

// Add a response interceptor to axiosInstance
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response) {
      // If error status is 401 and it's not a retry
      if (
        error.response.status === 401 &&
        !originalRequest._retry
      ) {
        // Check if the original request was to the RefreshToken endpoint
        if (
          originalRequest.url.includes("Authentication/RefreshToken")
        ) {
          // If the RefreshToken endpoint failed, do not retry
          // Handle session expiration
          store.dispatch(
            setAuthFailed(["Session expired. Please log in again."])
          );
          return Promise.reject(error);
        }

        // Mark the request as a retry
        originalRequest._retry = true;

        try {
          // Attempt to refresh the token using authAxiosInstance
          await authAxiosInstance.post("Authentication/RefreshToken");

          // Retry the original request using axiosInstance
          return axiosInstance(originalRequest);
        } catch (refreshError) {
          // If refresh fails, handle accordingly
          store.dispatch(
            setAuthFailed(["Session expired. Please log in again."])
          );
          return Promise.reject(refreshError);
        }
      }
    }

    // If the error is not a 401 or other conditions, reject the promise
    return Promise.reject(error);
  }
);

export default axiosInstance;
