// src/components/Auth/PasswordStrengthMeter.tsx
import React from 'react';
import styled from 'styled-components';

const StrengthBarContainer = styled.div`
  width: 100%;
  height: 8px;
  background-color: ${({ theme }) => theme.colors.border};
  border-radius: ${({ theme }) => theme.card.borderRadius};
  margin-bottom: ${({ theme }) => theme.spacing(1)};
  overflow: hidden;
`;

const StrengthBar = styled.div<{ strength: number }>`
  height: 100%;
  width: ${({ strength }) => strength}%;
  background-color: ${({ strength, theme }) => {
    if (strength < 40) return theme.colors.error;
    if (strength < 70) return theme.colors.secondary;
    return theme.colors.primary;
  }};
  transition: width 0.3s;
`;

interface PasswordStrengthMeterProps {
  password: string;
}

const PasswordStrengthMeter: React.FC<PasswordStrengthMeterProps> = ({ password }) => {
  const calculateStrength = () => {
    let strength = 0;
    if (password.length > 5) strength += 20;
    if (password.length > 8) strength += 20;
    if (/[A-Z]/.test(password)) strength += 20;
    if (/[0-9]/.test(password)) strength += 20;
    if (/[^A-Za-z0-9]/.test(password)) strength += 20;
    return strength;
  };

  const strength = calculateStrength();

  return (
    <StrengthBarContainer>
      <StrengthBar strength={strength} />
    </StrengthBarContainer>
  );
};

export default PasswordStrengthMeter;
