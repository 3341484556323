import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthenticationState, User } from "../../types/authentication.types";

const initialState: AuthenticationState = {
  isAuthenticated: false,
  user: null,
  loading: false,
  errors: []
};

const authenticationSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setLoginSuccess(state, action: PayloadAction<User>) {
      var user = action.payload;

      return {
        ...state,
        loading: false,
        isAuthenticated: user && user.userId > 0 ? true : false,
        user: user,
      };
    },
    setLoginFailed(state, action: PayloadAction<Array<string>>) {
      state.loading = false;
      state.errors = action.payload;
      state.isAuthenticated = false;
      state.user = null;
    },
    setRegisterFailed(state, action: PayloadAction<string[]>) {
      state.errors = action.payload;
    },
    setAuthFailed(state, action: PayloadAction<string[]>) {
      state.errors = action.payload;
    },
    setRegisterSuccess(state, action: PayloadAction<User>) {
      state.user = action.payload;
      state.errors = [];
    },
    clearErrors(state) {
      state.errors = [];
    },
  },
});

export const { setLoading, setLoginSuccess, setLoginFailed, setRegisterFailed, setRegisterSuccess, clearErrors, setAuthFailed} =
  authenticationSlice.actions;

  // Selector to get error messages
export const selectAuthErrors = (state: { authentication: AuthenticationState }) =>
  state.authentication.errors;

export default authenticationSlice.reducer;
