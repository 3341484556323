// src/components/NavBar/MenuIcon.tsx
import styled from 'styled-components';

const MenuIcon = styled.div`
  display: none;
  cursor: pointer;

  span {
    display: block;
    width: 25px;
    height: 3px;
    margin: 5px;
    background-color: ${({ theme }) => theme.colors.navText};
  }

  @media (max-width: 768px) {
    display: block;
  }
`;

export default MenuIcon;
