// src/components/InputField.tsx
import styled from "styled-components";

type InputFieldProps = {
  hasError?: boolean;
};

const InputField = styled.input<InputFieldProps>`
  width: 100%;
  padding: ${({ theme }) => theme.spacing(1)};
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: ${({ theme }) => theme.card.borderRadius};
  font-size: 1rem;
  font-family: ${({ theme }) => theme.fonts.main};
  color: ${({ theme }) => theme.colors.textPrimary};
  background-color: ${({ theme }) => theme.colors.card};
  transition: border-color 0.3s, box-shadow 0.3s;

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.focus};
    box-shadow: 0 0 0 3px rgba(63, 81, 181, 0.2);
  }

  border-color: ${({ theme, hasError }) =>
    hasError ? theme.colors.error : theme.colors.border};

  &:focus {
    border-color: ${({ theme, hasError }) =>
      hasError ? theme.colors.error : theme.colors.focus};
  }
`;

export default InputField;
