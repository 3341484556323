import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Binder {
  id: string;
  title: string;
}

interface UserBindersState {
  binders: Binder[];
}

const initialState: UserBindersState = {
  binders: [
    { id: 'op05', title: 'Binder OP05' },
    { id: 'op08', title: 'Binder OP08' },
  ],
};

const userBindersSlice = createSlice({
  name: 'userBinders',
  initialState,
  reducers: {
    addBinder(state, action: PayloadAction<Binder>) {
      state.binders.push(action.payload);
    },
    removeBinder(state, action: PayloadAction<string>) {
      state.binders = state.binders.filter((binder) => binder.id !== action.payload);
    },
  },
});

export const { addBinder, removeBinder } = userBindersSlice.actions;

export default userBindersSlice.reducer;
