import axios from "axios";
import axiosInstance, {authAxiosInstance} from "../api/axiosInstance.ts";
import { User } from "../types/authentication.types.ts";

axios.defaults.withCredentials = true;

interface AuthenticateResponse {
  message: string;
  successful: boolean;
  user?: User;
}

interface RegisterResponse {
  message: string;
  successful: boolean;
  user?: User;
}

/**
 * Authenticate the user
 * @param {string} userName
 * @param {string} password
 * @returns {Promise<AuthenticateResponse>}
 */
export const authenticateUser = async (
  userName: string,
  password: string
): Promise<AuthenticateResponse> => {
  try {
    const response = await authAxiosInstance.post<User>(
      "Authentication/Authenticate",
      {
        Username: userName,
        Password: password,
      }
    );

    return {
      message: "",
      successful: true,
      user: response.data,
    };
  } catch (error: any) {
    if (error.response) {
      return {
        message: error.response.data.message || "Authentication failed.",
        successful: false,
      };
    } else if (error.request) {
      console.error("No response received", error.request);
    } else {
      console.error("Request setup error", error.message);
    }

    return {
      message: "Unable to authenticate",
      successful: false,
    };
  }
};

/**
 * Register a new user
 * @param {string} userName
 * @param {string} email
 * @param {string} password
 * @returns {Promise<RegisterResponse>}
 */
export const registerUser = async (
  userName: string,
  email: string,
  password: string
): Promise<RegisterResponse> => {
  try {
    const response = await axiosInstance.post<User>("Authentication/Register", {
      Username: userName,
      EmailAddress: email,
      Password: password,
    });

    return {
      message: "",
      successful: true,
      user: response.data,
    };
  } catch (error: any) {
    if (error.response) {
      return {
        message: error.response.data.message || "Registration failed.",
        successful: false,
      };
    } else if (error.request) {
      console.error("No response received", error.request);
    } else {
      console.error("Request setup error", error.message);
    }

    return {
      message: "Unable to register",
      successful: false,
    };
  }
};


export const fetchCurrentUser = async (): Promise<AuthenticateResponse> => {
  try {
    const response = await axiosInstance.get<User>(
      "/Authentication/CurrentUser"
    );

    console.log("response", response);

    if (response.status != 200) {
      return {
        message: "Failed to fetch the current user",
        successful: false,
      };
    }

    return {
      message: "",
      successful: true,
      user: response.data,
    };
  } catch (error) {
    console.error("Failed to fetch current user:", error);

    return {
      message: error,
      successful: false,
    };
  }
};
