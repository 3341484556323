// src/pages/LoginPage.tsx
import React, { useEffect, useState } from "react";
import LoginContainer from "../components/Auth/AuthContainer.tsx";
import FormContainer from "../components/Auth/FormContainer.tsx";
import Label from "../components/Auth/Label.tsx";
import InputField from "../components/Auth/InputField.tsx";
import Button from "../components/Auth/Button.tsx";
import ForgotPasswordLink from "../components/Auth/ForgotPasswordLink.tsx";
import CheckboxLabel from "../components/Auth/CheckboxLabel.tsx";
import SignUpLink from "../components/Auth/SignUpLink.tsx";
import { useDispatch, useSelector } from "react-redux";
import { authenticateThunk } from "../store/thunks/authentication.ts";
import { AppDispatch, RootState } from "../store/store.ts";
import ErrorMessage from "../components/Auth/ErrorMessage.tsx";
import { selectAuthErrors } from "../store/slices/authenticationSlice.ts";

const LoginPage: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const serverErrorMessages = useSelector((state: RootState) =>
    selectAuthErrors(state)
  );

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  useEffect(() => {
    if (serverErrorMessages && serverErrorMessages.length > 0)
      setErrorMessages(serverErrorMessages);
  }, [serverErrorMessages]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const errors: string[] = [];
    if (!username.trim()) errors.push("Username is required.");
    if (!password.trim()) errors.push("Password is required.");

    if (errors.length) {
      setErrorMessages(errors);
      return;
    }

    setErrorMessages([]);
    dispatch(authenticateThunk(username, password));
  };

  return (
    <LoginContainer>
      <FormContainer onSubmit={handleSubmit}>
        <h2 style={{ marginBottom: "16px", textAlign: "center" }}>
          Welcome Back
        </h2>

        {errorMessages && errorMessages.length > 0 && (
          <div>
            {errorMessages.map((msg, index) => (
              <ErrorMessage key={index}>{msg}</ErrorMessage>
            ))}
          </div>
        )}

        <Label htmlFor="username">Username or Email</Label>
        <InputField
          type="text"
          id="username"
          name="username"
          placeholder="Enter your username or email"
          value={username}
          onChange={(e) => {
            setErrorMessages([]);
            setUsername(e.target.value);
          }}
          required
        />

        <Label htmlFor="password">Password</Label>
        <InputField
          type="password"
          id="password"
          name="password"
          autoComplete="current-password"
          placeholder="Enter your password"
          value={password}
          onChange={(e) => {
            setErrorMessages([]);
            setPassword(e.target.value);
          }}

          required
        />

        <CheckboxLabel>
          <input
            type="checkbox"
            name="rememberMe"
            checked={rememberMe}
            onChange={(e) => setRememberMe(e.target.checked)}
          />
          Remember Me
        </CheckboxLabel>

        <Button type="submit">Login</Button>

        <ForgotPasswordLink to="/forgot-password">
          Forgot Password?
        </ForgotPasswordLink>

        <p style={{ marginTop: "24px", textAlign: "center" }}>
          Don't have an account? <SignUpLink to="/signup">Sign Up</SignUpLink>
        </p>
      </FormContainer>
    </LoginContainer>
  );
};

export default LoginPage;
