// src/components/SignUpLink.tsx
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const SignUpLink = styled(Link)`
  margin-top: ${({ theme }) => theme.spacing(3)};
  font-size: 0.9rem;
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export default SignUpLink;
