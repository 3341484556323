// src/components/Label.tsx
import styled from 'styled-components';

const Label = styled.label`
  margin-bottom: ${({ theme }) => theme.spacing(0.5)};
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.textSecondary};
  font-family: ${({ theme }) => theme.fonts.main};
`;

export default Label;
