// src/components/FormContainer.tsx
import styled from 'styled-components';

const FormContainer = styled.form`
  width: 100%;
  max-width: 400px;
  padding: ${({ theme }) => theme.spacing(4)};
  background-color: ${({ theme }) => theme.colors.card};
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: ${({ theme }) => theme.card.borderRadius};
  box-shadow: ${({ theme }) => theme.card.shadow};
  display: flex;
  flex-direction: column;
`;

export default FormContainer;
