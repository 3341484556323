// src/components/CheckboxLabel.tsx
import styled from 'styled-components';

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  font-size: 0.9rem;
  color: ${({ theme }) => theme.colors.textSecondary};

  input {
    margin-right: ${({ theme }) => theme.spacing(1)};
  }
`;

export default CheckboxLabel;
