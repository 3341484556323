// src/components/NavBar/DropdownMenu.tsx
import styled from 'styled-components';

const DropdownMenu = styled.ul`
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: ${({ theme }) => theme.colors.navBackground};
  border: 1px solid ${({ theme }) => theme.colors.navBorder};
  border-radius: ${({ theme }) => theme.card.borderRadius};
  min-width: 200px; /* Increased min-width for better content fit */
  list-style: none;
  padding: ${({ theme }) => theme.spacing(1)} 0;
  z-index: 1000;
  box-shadow: ${({ theme }) => theme.card.shadow}; /* Added shadow for depth */

  li {
    padding: 0;

    a {
      display: block;
      color: ${({ theme }) => theme.colors.navText};
      text-decoration: none;
      font-size: 0.9rem;
      padding: ${({ theme }) => theme.spacing(1)} ${({ theme }) => theme.spacing(2)};

      &:hover {
        background-color: ${({ theme }) => theme.colors.navHover};
        color: ${({ theme }) => theme.colors.primary};
      }
    }
  }

  /* Remove any hover-induced changes that might cause jumping */
  &:hover {
    display: block;
  }
`;

export default DropdownMenu;
