// src/components/NavBar/UserSection.tsx
import styled from 'styled-components';

const UserSection = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  /* Styles for the username and arrow */
  .username {
    display: flex;
    align-items: center;
    cursor: pointer;

    span {
      color: ${({ theme }) => theme.colors.navText};
      font-size: 1rem;
      margin-right: ${({ theme }) => theme.spacing(0.5)};
    }

    /* Arrow icon */
    svg {
      width: 12px;
      height: 12px;
      fill: ${({ theme }) => theme.colors.navText};
      transition: transform 0.2s;

      &.open {
        transform: rotate(180deg);
      }
    }
  }
`;


export default UserSection;


