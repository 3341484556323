// // src/components/NavBar/NavLinks.tsx
// import styled from 'styled-components';

// const NavLinks = styled.ul<{ menuOpen: boolean }>`
//   list-style: none;
//   display: flex;
//   align-items: center;
//   margin: 0;

//   /* Mobile Styles */
//   @media (max-width: 768px) {
//     flex-direction: column;
//     background-color: ${({ theme }) => theme.colors.navBackground};
//     position: absolute;
//     top: 100%;
//     left: 0;
//     right: 0;
//     display: ${({ menuOpen }) => (menuOpen ? 'flex' : 'none')};
//     border-bottom: 1px solid ${({ theme }) => theme.colors.navBorder};
//     z-index: 999;
//   }

//   overflow: visible;
// `;

// export default NavLinks;


// src/components/NavBar/NavLinks.tsx
import styled from 'styled-components';

const NavLinks = styled.ul<{ $menuOpen: boolean }>`
  list-style: none;
  display: flex;
  align-items: center;
  margin: 0;

  /* Mobile Styles */
  @media (max-width: 768px) {
    flex-direction: column;
    background-color: ${({ theme }) => theme.colors.navBackground};
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    display: ${({ $menuOpen }) => ($menuOpen ? 'flex' : 'none')};
    border-bottom: 1px solid ${({ theme }) => theme.colors.navBorder};
    z-index: 999;
  }
`;

export default NavLinks;
