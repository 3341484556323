import React, { useEffect } from 'react';
import '../../assets/styles/components/BinderPage.css';
import { useDispatch } from 'react-redux';
import { Card } from '../../types/card.types.ts';
import { updateUserCardQuantity } from '../../store/slices/cardSlice.ts';
import { fetchCurrentUserThunk } from '../../store/thunks/authentication.ts';
import { AppDispatch, AppThunk } from '../../store/store.ts';

interface BinderPageProps {
  cards: Card[];
  columns: number;
  rows: number;
  flipDirection: string;
  disableAnimation: boolean;
}

const BinderPage: React.FC<BinderPageProps> = ({ cards, columns, rows, flipDirection, disableAnimation }) => {
  const dispatch = useDispatch<AppDispatch>();

  const handleQuantityChange = (id: Card['id'], delta: number): void => {
    const card = cards.find((card) => card.id === id);
    const newQuantity = Math.max(0, (card?.quantity || 0) + delta);
    dispatch(updateUserCardQuantity({ id, quantity: newQuantity }));
  };

  return (
    <div
      className={`binder-page ${disableAnimation ? '' : flipDirection}`}
      style={{
        gridTemplateColumns: `repeat(${columns}, 1fr)`,
        gridTemplateRows: `repeat(${rows}, 1fr)`,
      }}
    >
      {cards?.map((card) => (
        <div key={card.id} className={`card-slot ${card.quantity && card.quantity > 0 ? '' : 'missing'}`}>
          <img
            src={card.imagePath}
            alt={card.title}
            className={`card-image ${card.quantity && card.quantity > 0 ? '' : 'grayed-out'}`}
          />
          {card.quantity === 0 && <div className="missing-banner">Missing</div>}
          <div className="card-quantity">
            <button onClick={() => handleQuantityChange(card.id, -1)}>-</button>
            <span>{card.quantity}</span>
            <button onClick={() => handleQuantityChange(card.id, 1)}>+</button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default BinderPage;
