// src/components/NavBar/Logo.tsx
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Logo = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.primary};

  img {
    height: 40px;
    margin-right: ${({ theme }) => theme.spacing(1)};
    transition: margin-right 0.3s ease;
  }

  span {
    font-family: ${({ theme }) => theme.fonts.heading};
    font-size: 1.5rem;
    transition: opacity 0.3s ease;

    @media (max-width: 768px) {
      display: none;
    }
  }

  @media (max-width: 768px) {
    img {
      margin-right: 0;
    }
  }
`;

export default Logo;
