// src/components/NavBar/UserDropdownMenu.tsx
import styled from 'styled-components';

const UserDropdownMenu = styled.ul<{ isOpen: boolean }>`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  position: absolute;
  right: 0;
  top: 100%;
  background-color: ${({ theme }) => theme.colors.navBackground};
  border: 1px solid ${({ theme }) => theme.colors.navBorder};
  border-radius: ${({ theme }) => theme.card.borderRadius};
  min-width: 150px;
  list-style: none;
  padding: ${({ theme }) => theme.spacing(1)} 0;
  z-index: 1000;
  box-shadow: ${({ theme }) => theme.card.shadow};

  li {
    padding: 0;

    a {
      display: block;
      color: ${({ theme }) => theme.colors.navText};
      text-decoration: none;
      font-size: 0.9rem;
      padding: ${({ theme }) => theme.spacing(1)} ${({ theme }) => theme.spacing(2)};

      &:hover {
        background-color: ${({ theme }) => theme.colors.navHover};
        color: ${({ theme }) => theme.colors.primary};
      }
    }
  }
`;

export default UserDropdownMenu;
