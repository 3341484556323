import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./store/store.ts";
import App from "./pages/App.tsx";

import { ThemeProvider } from "styled-components";
import theme from "./assets/styles/theme.ts";
import GlobalStyles from "./assets/styles/globalStyles.ts";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <App />{" "}
    </ThemeProvider>
  </Provider>
);
