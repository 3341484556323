
import styled from 'styled-components';

// src/components/Button.tsx (Update styles if needed)
const Button = styled.button`
  background-color: ${({ theme }) => theme.colors.primary};
  color: white;
  font-size: 1rem;
  padding: ${({ theme }) => theme.spacing(1.5)};
  margin-top: ${({ theme }) => theme.spacing(2)};
  border: none;
  border-radius: ${({ theme }) => theme.card.borderRadius};
  cursor: pointer;
  font-family: ${({ theme }) => theme.fonts.main};
  transition: background-color 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary};
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(255, 193, 7, 0.4);
  }
`;

export default Button;
