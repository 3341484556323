/**
 * Get the base URL for the site for api calls
 * @returns The base URL for the api
 */
export const getAPIURL = (path: string) => {
    const host = window.location.hostname;
    return host.toLowerCase() === "localhost"
      ? `http://localhost:3000/api/MyTCC/${path}`
      : `https://piviv.co.uk/api/MyTCC/${path}`;
  };
  