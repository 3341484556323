import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from '../store/store.ts';
import { addBinder, removeBinder } from '../store/slices/userBindersSlice.ts';
import NavBar from '../components/Navigation/NavBar.tsx';
import '../assets/styles/pages/Home.css';
import { useNavigate } from 'react-router-dom';

const Home: React.FC = () => {
  const navigate = useNavigate();

  const binders = useSelector((state: RootState) => state.userBinders.binders);
  const dispatch = useDispatch<AppDispatch>();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [menuOpenBinder, setMenuOpenBinder] = useState<string | null>(null);
  const [binderToDelete, setBinderToDelete] = useState<string | null>(null);

  const [newBinderName, setNewBinderName] = useState('');
  const [selectedSet, setSelectedSet] = useState('');

  const handleAddBinder = () => {
    if (newBinderName.trim() && selectedSet) {
      dispatch(
        addBinder({ id: Date.now().toString(), title: `${newBinderName} (${selectedSet})` })
      );
      setNewBinderName('');
      setSelectedSet('');
      setIsModalOpen(false);
    }
  };

  const handleDeleteBinder = () => {
    if (binderToDelete) {
      dispatch(removeBinder(binderToDelete));
      setBinderToDelete(null);
      setMenuOpenBinder(null);
    }
  };

  const toggleMenu = (binderId: string) => {
    setMenuOpenBinder((prev) => (prev === binderId ? null : binderId));
  };

  return (
    <>
      <NavBar />
      <div className="home-container">
        <h1>Your Binders</h1>
        <div className="binder-grid">
          {binders.map((binder) => (
            <div
              key={binder.id}
              className="binder-item"
              onClick={() => navigate(`/binder/${binder.id}`)}
              style={{ cursor: 'pointer' }}
            >
              <img src="/images/binder-cover.jpg" alt={binder.title} />
              <p>{binder.title}</p>
              <div
  className="ellipsis-menu"
  onClick={(e) => e.stopPropagation()} // Prevent navigation when interacting with the menu
>
  <div
    className="ellipsis-icon"
    onClick={() => toggleMenu(binder.id)}
  >
    &#x2026; {/* Horizontal ellipsis */}
  </div>
  {menuOpenBinder === binder.id && (
    <div className="menu-dropdown">
      <button
        onClick={() => {
          console.log(`Renaming ${binder.id}`);
          setMenuOpenBinder(null); // Close the menu
        }}
      >
        Rename
      </button>
      <button
        onClick={(e) => {
          e.stopPropagation();
          setBinderToDelete(binder.id);
          setMenuOpenBinder(null); // Close the menu
        }}
      >
        Delete
      </button>
    </div>
  )}
</div>

            </div>
          ))}
          <div className="add-binder-item" onClick={() => setIsModalOpen(true)}>
            <div className="add-icon">+</div>
            <p>Add Binder</p>
          </div>
        </div>

        {/* Add Binder Modal */}
        {isModalOpen && (
          <div className="modal-overlay">
            <div className="modal">
              <h2>Create New Binder</h2>
              <input
                type="text"
                placeholder="Binder Name"
                value={newBinderName}
                onChange={(e) => setNewBinderName(e.target.value)}
              />
              <select
                value={selectedSet}
                onChange={(e) => setSelectedSet(e.target.value)}
              >
                <option value="">Select Set</option>
                <option value="op05">OP05</option>
                <option value="op06">OP06</option>
                <option value="op09">OP09</option>
              </select>
              <div className="modal-actions">
                <button onClick={() => setIsModalOpen(false)}>Cancel</button>
                <button onClick={handleAddBinder}>Add Binder</button>
              </div>
            </div>
          </div>
        )}

        {/* Confirm Delete Modal */}
        {binderToDelete && (
          <div className="modal-overlay">
            <div className="modal">
              <h2>Are you sure?</h2>
              <p>Do you really want to delete this binder? This action cannot be undone.</p>
              <div className="modal-actions">
                <button onClick={() => setBinderToDelete(null)}>Cancel</button>
                <button onClick={handleDeleteBinder}>Delete</button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Home;
