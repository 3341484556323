// src/components/ErrorMessage.tsx
import styled from 'styled-components';

const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.colors.error};
  font-size: 0.9rem;
  margin-top: ${({ theme }) => theme.spacing(0.5)};
`;

export default ErrorMessage;
