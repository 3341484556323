// src/components/NavBar/index.tsx
import React, { useState, useEffect, useRef } from "react";
import NavBar, { NavBarContent } from "./NavBar.tsx";
import Logo from "./Logo.tsx";
import NavLinks from "./NavLinks.tsx";
import NavLinkItem from "./NavLinkItem.tsx";
import DropdownMenu from "./DropdownMenu.tsx";
import UserSection from "./UserSection.tsx";
import MenuIcon from "./MenuIcon.tsx";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import Avatar from "./Avatar.tsx";
import UserDropdownMenu from "./UserDropdownMenu.tsx";

const NavigationBar: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [userMenuOpen, setUserMenuOpen] = useState(false);

  const userMenuRef = useRef<HTMLDivElement>(null);

  const user = useSelector((state: RootState) => state.authentication.user);

  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };

  const handleUserMenuToggle = () => {
    setUserMenuOpen(!userMenuOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        userMenuRef.current &&
        !userMenuRef.current.contains(event.target as Node)
      ) {
        setUserMenuOpen(false);
      }
    };

    if (userMenuOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [userMenuOpen]);

  return (
    <NavBar>
      <NavBarContent>
        <Logo to="/">
          <img src="/path/to/logo.png" alt="My TCC Logo" />
          <span>My TCC</span>
        </Logo>

        {/* Desktop Navigation Links */}
        <NavLinks
          style={{ display: menuOpen ? "flex" : undefined }}
          $menuOpen={menuOpen}
        >
          <NavLinkItem>
            <Link to="/binders">Binders</Link>
          </NavLinkItem>
          <NavLinkItem>
            <Link to="/collections">Collections</Link>
          </NavLinkItem>
          <NavLinkItem>
            <Link to="/catalogue">Catalogue</Link>
            <DropdownMenu>
              <li>
                <Link to="/catalogue/pokemon">Pokémon</Link>
              </li>
              <li>
                <Link to="/catalogue/one-piece">One Piece</Link>
              </li>
              <li>
                <Link to="/catalogue/magic">Magic: The Gathering</Link>
              </li>
              <li>
                <Link to="/catalogue/yu-gi-oh">Yu-Gi-Oh!</Link>
              </li>
              <li>
                <Link to="/catalogue/digimon">Digimon</Link>
              </li>
            </DropdownMenu>
          </NavLinkItem>
          <NavLinkItem>
            <Link to="/about">About Us</Link>
          </NavLinkItem>
        </NavLinks>

        {/* User Section */}
        <UserSection ref={userMenuRef}>
          {user ? (
            <>
              <Avatar
                src={user.avatar || "/images/avatars/test.png"}
                alt={`${user.username}'s avatar`}
              />
              <div className="username" onClick={handleUserMenuToggle}>
                <span>{user.username}</span>
                <svg className={userMenuOpen ? "open" : ""} viewBox="0 0 24 24">
                  <path d="M7 10l5 5 5-5z" />
                </svg>
              </div>
              {userMenuOpen && (
                <UserDropdownMenu isOpen={userMenuOpen}>
                  <li>
                    <Link to="/profile">Profile</Link>
                  </li>
                  <li>
                    <Link to="/settings">Settings</Link>
                  </li>
                  <li>
                    <Link to="/logout">Logout</Link>
                  </li>
                </UserDropdownMenu>
              )}
            </>
          ) : (
            <>
              <Link to="/login">Login</Link>
              <Link to="/signup">Sign Up</Link>
            </>
          )}
        </UserSection>

        {/* Mobile Menu Icon */}
        <MenuIcon onClick={handleMenuToggle}>
          <span></span>
          <span></span>
          <span></span>
        </MenuIcon>
      </NavBarContent>
    </NavBar>
  );
};

export default NavigationBar;
