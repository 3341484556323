import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Home.tsx';
import Binder from './Binder.tsx';
import LoginPage from './LoginPage.tsx';
import SignUpPage from './SignUpPage.tsx';
import NavigationBar from '../components/Navigation/index.tsx';
import { fetchCurrentUserThunk } from '../store/thunks/authentication.ts';
import { AppDispatch } from '../store/store.ts';

const App: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    // On app initialization, check if the user is authenticated
    dispatch(fetchCurrentUserThunk());
  }, [dispatch]);

  return (
    <Router>
       <NavigationBar />

      <div style={{ paddingTop: '80px' }}>
      <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/signup" element={<SignUpPage />} />
      <Route path="/binder/:binderId" element={<Binder columns={3} rows={3} />} />
    </Routes>
      </div>
    </Router>
  );
};

export default App; 