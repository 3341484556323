// cardSlice.ts

import { createSlice } from '@reduxjs/toolkit';
import { Card, UserMetadata } from '../../types/card.types';

interface CardState {
  allCards: Card[];
  userCards: Record<number, number>;
  userMetadata: UserMetadata;
} 

const initialState : CardState = {
  allCards: [
    ...Array.from({ length: 127 }, (_, index) : Card => ({
      id: index + 1,
      title: `Card OP05-${index + 1}`,
      description: `Description for OP05 card number ${index + 1}.`,
      rarity: (index % 10 === 0) ? "Rare" : "Common" as const,
      imagePath: `/images/onepiece/op05/${index + 1}.jpg`,
      cardNumber: `OP05-${index + 1}`,
      setName: 'OP05',
      cardType: 'One Piece',
    })),
    ...Array.from({ length: 150 }, (_, index) : Card => ({
      id: 128 + index,
      title: `Card OP09-${index + 1}`,
      description: `Description for OP09 card number ${index + 1}.`,
      rarity: (index % 10 === 0) ? "Rare" : "Common" as const,
      imagePath: `/images/onepiece/op09/${index + 1}.png`, // Placeholder image
      cardNumber: `OP09-${index + 1}`,
      setName: 'OP09',
      cardType: 'One Piece',
    })),
  ],
  userCards: {},
  userMetadata: {
    cardTypes: { 'One Piece': true },
    sets: { OP05: true, OP09: true },
    defaultCardType: 'One Piece',
    defaultSet: 'OP05',
  },
};


const cardSlice = createSlice({
  name: 'cards',
  initialState,
  reducers: {
    setUserCardData(state, action) {
      state.userCards = action.payload;
    },
    updateUserCardQuantity(state, action) {
      const { id, quantity } = action.payload;
      const card = state.allCards.find((c) => c.id === id);

      if (card) {
        state.userCards[id] = Math.max(0, quantity);
        state.userMetadata.cardTypes[card.cardType] = true;
        state.userMetadata.sets[card.setName] = true;
      }
    },
    setDefaultCardType(state, action) {
      state.userMetadata.defaultCardType = action.payload;
    },
    setDefaultSet(state, action) {
      state.userMetadata.defaultSet = action.payload;
    },
  },
});

export const {
  setUserCardData,
  updateUserCardQuantity,
  setDefaultCardType,
  setDefaultSet,
} = cardSlice.actions;

export default cardSlice.reducer;
