import {
  authenticateUser,
  registerUser,
  fetchCurrentUser,
} from "../../api/AuthenticationAPI.ts";
import {
  setLoading,
  setLoginFailed,
  setLoginSuccess,
  setRegisterFailed,
  setRegisterSuccess,
} from "../slices/authenticationSlice.ts";
import { setUserCardData } from "../slices/cardSlice.ts";
import { AppThunk } from "../store.ts";

export const authenticateThunk =
  (username: string, password: string): AppThunk =>
  async (dispatch: any) => {
    try {
      dispatch(setLoading(true));

      const response = await authenticateUser(username, password);

      if (!response.successful) {
        dispatch(setLoginFailed([response.message]));
      }

      if (response.successful && response.user)
        dispatch(setLoginSuccess(response.user));
    } catch (error) {
      dispatch(setLoginFailed(["Unable to login"]));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const signUpThunk =
  (username: string, email: string, password: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setLoading(true));

      const response = await registerUser(username, email, password);

      if (!response.successful) {
        dispatch(setRegisterFailed([response.message]));
        return;
      }

      if (response.successful && response.user) {
        dispatch(setRegisterSuccess(response.user));
        // Optionally redirect the user or update the UI
      }
    } catch (error) {
      dispatch(setRegisterFailed(["Unable to sign up"]));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const fetchCurrentUserThunk = (): AppThunk => async (dispatch) => {
  try {
    // TODO: remove once we have the data from the DB Define interface for the mock data structure
    interface UserCardData {
      [key: number]: number;
    }

    //TODO: Move to mock file / mock api
    const mockUserData: UserCardData = {
      1: 3, // Card ID 1, user has 3
      2: 1, // Card ID 2, user has 0
      // Add more user card quantities
    };

    dispatch(setUserCardData(mockUserData));

    dispatch(setLoading(true));

    const response = await fetchCurrentUser();

    if (response.successful && response.user) {
      dispatch(setLoginSuccess(response.user));
    }
  } catch (error) {
    // You might want to dispatch an action to handle errors
    console.error("Failed to fetch current user:", error);
  } finally {
    dispatch(setLoading(false));
  }
};
