// src/pages/SignUpPage.tsx
import React, { useState } from "react";
import AuthContainer from "../components/Auth/AuthContainer.tsx";
import FormContainer from "../components/Auth/FormContainer.tsx";
import Label from "../components/Auth/Label.tsx";
import InputField from "../components/Auth/InputField.tsx";
import Button from "../components/Auth/Button.tsx";
import ErrorMessage from "../components/Auth/ErrorMessage.tsx";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store/store";
import { signUpThunk } from "../store/thunks/authentication.ts";
import { clearErrors, selectAuthErrors, setRegisterFailed } from "../store/slices/authenticationSlice.ts";
import { Link } from "react-router-dom";
import PasswordStrengthMeter from "../components/Auth/PasswordStrengthMeter.tsx";

const SignUpPage: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const errorMessages = useSelector((state: RootState) =>
    selectAuthErrors(state)
  );

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const errors: string[] = [];
    if (!username.trim()) errors.push("Username is required.");
    if (!email.trim()) errors.push("Email is required.");
    else if (!/^\S+@\S+\.\S+$/.test(email)) errors.push("Email is invalid.");
    if (!password.trim()) errors.push("Password is required.");
    if (password !== confirmPassword) errors.push("Passwords do not match.");

    if (errors.length) {
      dispatch(setRegisterFailed(errors));
      return;
    }

    // Clear errors and dispatch sign-up action
    dispatch(clearErrors());
    dispatch(signUpThunk(username, email, password));
  };

  return (
    <AuthContainer>
      <FormContainer onSubmit={handleSubmit}>
        <h2 style={{ marginBottom: "16px", textAlign: "center" }}>
          Create Account
        </h2>

        {errorMessages && errorMessages.length > 0 && (
          <div>
            {errorMessages.map((msg, index) => (
              <ErrorMessage key={index}>{msg}</ErrorMessage>
            ))}
          </div>
        )}

        <Label htmlFor="username">Username</Label>
        <InputField
          type="text"
          id="username"
          name="username"
          placeholder="Enter your username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />

        <Label htmlFor="email">Email</Label>
        <InputField
          type="email"
          id="email"
          name="email"
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />

        <Label htmlFor="password">Password</Label>
        <InputField
          type="password"
          id="password"
          name="password"
          placeholder="Enter your password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <PasswordStrengthMeter password={password} />

        <Label htmlFor="confirmPassword">Confirm Password</Label>
        <InputField
          type="password"
          id="confirmPassword"
          name="confirmPassword"
          placeholder="Confirm your password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
        />

        <Button type="submit">Sign Up</Button>

        <p style={{ marginTop: "24px", textAlign: "center" }}>
          Already have an account? <Link to="/login">Login</Link>
        </p>
      </FormContainer>
    </AuthContainer>
  );
};

export default SignUpPage;
