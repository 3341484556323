// src/components/NavBar/NavBar.tsx
import styled from 'styled-components';

const NavBar = styled.nav`

`;


export const NavBarContent = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.navBackground};
  border-bottom: 1px solid ${({ theme }) => theme.colors.navBorder};
  padding: ${({ theme }) => theme.spacing(1)} ${({ theme }) => theme.spacing(2)};
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: 1000;
`;



export default NavBar;
