// src/theme.ts
import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
  colors: {
    primary: '#6200ee',      // Deep Purple
    secondary: '#03dac6',    // Teal
    background: '#f5f5f5',   // Light Gray
    card: '#ffffff',         // White
    textPrimary: '#000000',  // Black
    textSecondary: '#757575',// Dark Gray
    border: '#e0e0e0',       // Light Gray
    error: '#b00020',        // Dark Red
    focus: '#6200ee',        // Deep Purple (same as primary)
    success: '#4caf50',
    navBackground: '#ffffff',
    navText: '#000000',
    navHover: '#f0f0f0',
    navBorder: '#e0e0e0',
  },
  fonts: {
    main: `'Roboto', sans-serif`,
    heading: `'Raleway', sans-serif`,
  },
  spacing: (factor) => `${factor * 8}px`,
  card: {
    borderRadius: '8px',
    shadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
  },
};

export default theme;
