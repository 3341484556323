import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import cardReducer from './slices/cardSlice.ts';
import userBindersReducer from './slices/userBindersSlice.ts';
import authenticationReducer from './slices/authenticationSlice.ts'

export const store = configureStore({
  reducer: {
    authentication: authenticationReducer,
    cards: cardReducer,
    userBinders: userBindersReducer,
  },
});

// Add type exports
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch; 
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;