// src/components/NavBar/NavLinkItem.tsx
import styled from 'styled-components';
import DropdownMenu from './DropdownMenu.tsx';


const NavLinkItem = styled.li`
  position: relative;
  margin: 0 ${({ theme }) => theme.spacing(1)};

  a {
    color: ${({ theme }) => theme.colors.navText};
    text-decoration: none;
    font-size: 1rem;
    padding: ${({ theme }) => theme.spacing(0.5)} 0;

    &:hover {
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  /* Show DropdownMenu on hover */
  &:hover ${DropdownMenu} {
    display: block;
  }
`;

export default NavLinkItem;



