// src/components/ForgotPasswordLink.tsx
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const ForgotPasswordLink = styled(Link)`
  margin-top: ${({ theme }) => theme.spacing(1)};
  font-size: 0.9rem;
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: none;
  align-self: flex-end;

  &:hover {
    text-decoration: underline;
  }
`;

export default ForgotPasswordLink;
