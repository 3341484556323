import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import BinderPage from '../components/Binder/BinderPage.tsx';
import Pagination from '../components/Binder/Pagination.tsx';
import '../assets/styles/components/Binder.css';
import { RootState } from '../types/store.types.ts';

interface BinderProps {
  columns: number;
  rows: number;
  disableAnimation?: boolean;
  binderId: string;
}

const Binder: React.FC<BinderProps> = ({ columns, rows, disableAnimation = false, binderId }) => {
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [flipDirection, setFlipDirection] = useState<string>('');
  const [selectedCardType, setSelectedCardType] = useState<string | null>(null);
  const [selectedSet, setSelectedSet] = useState<string | null>(null);

  const allCards = useSelector((state: RootState) => state.cards.allCards);
  const userCards = useSelector((state: RootState) => state.cards.userCards);
  const userMetadata = useSelector((state: RootState) => state.cards.userMetadata);


  const cardType = selectedCardType || userMetadata.defaultCardType || null;
  const set = selectedSet || userMetadata.defaultSet || null;

  const binder = useSelector((state: RootState) => 
    state.userBinders.binders.find(b => b.id === binderId)
  );

  // Update filteredCards to consider the binder's set
  const filteredCards = allCards
    .filter((card) => (cardType ? card.cardType === cardType : true))
    .filter((card) => (binder ? card.setName === binder.set : true))  // Use binder's set
    .map((card) => ({
      ...card,
      quantity: userCards[card.id] || 0,
    }));

  const cardsPerPage = columns * rows;
  const totalPages = Math.ceil(filteredCards.length / cardsPerPage);

  const currentCards = filteredCards.slice(
    currentPage * cardsPerPage,
    (currentPage + 1) * cardsPerPage
  );

  // Dynamic card dimensions
  const cardWidth = 300; // Card width in pixels
  const cardAspectRatio = 2.5 / 3.5; // Aspect ratio for trading cards
  const cardHeight = cardWidth / cardAspectRatio; // Calculate card height
  const gap = 5; // Space between cards
  const padding = 20; // Binder padding

  // Binder dimensions based on rows, columns, and card size
  const binderWidth = columns * (cardWidth + gap) - gap + padding * 2;
  const binderHeight = rows * (cardHeight + gap) - gap + padding * 2;


  return (
    <div>

      <div className="binder-container" style={{ padding: `${padding}px` }}>
        {/* Pagination controls */}
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={(page) => setCurrentPage(page)}
        />

        {/* Binder with calculated dimensions */}
        <div
          className="binder"
          style={{
            width: `${binderWidth}px`,
            height: `${binderHeight}px`,
          }}
        >
          <BinderPage
            cards={currentCards}
            columns={columns}
            rows={rows}
            flipDirection={flipDirection}
            disableAnimation={disableAnimation}
          />
        </div>
      </div>
    </div>
  );
};

export default Binder;
