// src/components/NavBar/Avatar.tsx
import styled from 'styled-components';

const Avatar = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: ${({ theme }) => theme.spacing(1)};
`;

export default Avatar;
